<template>
  <div class="index">
    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch />
    <!-- 页面主题图 -->
    <div class="back"></div>
    <ServerTop
      title="全网品牌搜索占位"
      wera="品牌全网搜索优先展现，提升品牌网络形象"
      jiage="15800"
    />
    <div class="header01">
      <div class="lan"></div>
      <p>服务详情</p>
    </div>
    <div class="title01">
      <div class="title">
        <img src="../imger/AR/title.png" alt="品牌占位" class="titletubiaoleft" />
        <p>什么是品牌占位？</p>
        <img src="../imger/AR/title.png" alt="品牌占位" class="titletubiaoright" />
      </div>
      <div class="title01word">
        <p>
          所谓品牌搜索占位,就是基于全网主流搜索入口,抢先将品牌的核心优势和官方内容在搜索引擎上进行词条占
        </p>
        <p>
          位和内容布局,并通过占位沉淀技术实现品牌内容的优先展示和持续性沉淀，
        </p>
        <p>最终达到唯一的永久性占位目的，为品牌构建起强大的互联网公信力。</p>
      </div>
      <img src="../imger/station/1-1.png" alt="品牌搜索占位-品牌全网布局SEO优化" class="pricture" />
    </div>

    <div class="title01 title02">
      <div class="title">
        <img src="../imger/AR/title.png" alt="品牌占位" class="titletubiaoleft" />
        <p>为什么在做品牌搜索占位？</p>
        <img src="../imger/AR/title.png" alt="品牌占位" class="titletubiaoright" />
      </div>
      <div class="title01word">
        <p>
          大数据统计：90%以上企业品牌同名现象极为严重，且同一品牌名数量不低于500家。企业如何才能在上
        </p>
        <p>
          百家甚至上万家同名品牌中，通过主流“搜索入口”搜索品牌名字时只出现自己唯一的品牌官方信息?
          基
        </p>
        <p>
          于此，灵标公司独家研发并推出“品牌搜索占位”技术，助力企业成功抓住互联网这个巨大市场。
        </p>
      </div>
      <div class="bigbox">
        <!-- 盒子一 -->
        <div class="box">
          <div class="boxhead">
            <img src="../imger/brand/yuan01.png" alt="品牌搜索占位" class="circle" />
          </div>
          <div class="boxbody">
            <p class="boxword">
              线上流量决定着企业的生存，利用好互联网才能有更好的发展。
            </p>
            <img class="boxpic" alt="品牌搜索占位" src="../imger/station/icon1.png" />
          </div>
        </div>
        <!-- 盒子二 -->
        <div class="box">
          <div class="boxhead">
            <img src="../imger/brand/yuan02.png" alt="品牌搜索占位" class="circle" />
          </div>
          <div class="boxbody">
            <p class="boxword">
              能有效的屏蔽同名品牌，实现全网搜索的唯一性，彰显自身品牌官方度与信任力。
            </p>
            <img class="boxpic" alt="品牌搜索占位" src="../imger/station/icon2.png" />
          </div>
        </div>
        <!-- 盒子三 -->
        <div class="box">
          <div class="boxhead">
            <img src="../imger/brand/yuan03.png" alt="品牌搜索占位" class="circle" />
          </div>
          <div class="boxbody">
            <p class="boxword">
              利于企业广告投放后转化率的递增，让公司产品在类产品选择中更具公信力。
            </p>
            <img class="boxpic" alt="品牌搜索占位" src="../imger/station/icon3.png" />
          </div>
        </div>
        <!-- 盒子四 -->
        <div class="box">
          <div class="boxhead">
            <img src="../imger/brand/yuan04.png" alt="品牌搜索占位" class="circle" />
          </div>
          <div class="boxbody">
            <p class="boxword">
              品牌搜索占位能最大限度的将垃圾信息和负面报道压制到搜索首页之后，降低经营风险。
            </p>
            <img class="boxpic" alt="品牌搜索占位" src="../imger/station/icon4.png" />
          </div>
        </div>
        <!-- 盒子五 -->
        <div class="box">
          <div class="boxhead">
            <img src="../imger/brand/yuan05.png" alt="品牌搜索占位" class="circle" />
          </div>
          <div class="boxbody">
            <p class="boxword">
              互联网讲究占先规则，提前布局品牌搜索占位，能有效稀释同品牌的流量，并将其流量为自己所用。
            </p>
            <img class="boxpic" alt="品牌搜索占位" src="../imger/station/icon5.png" />
          </div>
        </div>
        <!-- 盒子六 -->
        <div class="box">
          <div class="boxhead">
            <img src="../imger/brand/yuan06.png" alt="品牌搜索占位" class="circle" />
          </div>
          <div class="boxbody">
            <p class="boxword">
              品牌全网搜索占位信息展现周期长，省钱，后期运营简单，是企业发展壮大的根本建设。
            </p>
            <img class="boxpic" alt="品牌搜索占位" src="../imger/station/icon6.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="title03">
      <div class="title">
        <img src="../imger/AR/title.png" alt="品牌搜索占位" class="titletubiaoleft" />
        <p>案例</p>
        <img src="../imger/AR/title.png" alt="品牌搜索占位" class="titletubiaoright" />
      </div>
      <div class="title03word">
        <p>
          以灵标服务的北京格勤教育机构为例，全国“格勤”的同名企业有700多家。然而在百度上搜索“格勤”
        </p>
        <p>
          二字时，页面出现的企业只有北京格勤教育机构一家，且都是正面的官方信息。
        </p>
      </div>
      <img src="../imger/station/anli.png" alt="品牌搜索占位-品牌全网布局SEO优化" class="pricture" />
    </div>

    <div class="title01">
      <div class="title">
        <img src="../imger/AR/title.png" alt="品牌搜索占位" class="titletubiaoleft" />
        <p>国内外主流搜索入口</p>
        <img src="../imger/AR/title.png" alt="品牌搜索占位" class="titletubiaoright" />
      </div>
      <img src="../imger/station/zhuliu.png" alt="品牌全网布局" class="zhuliu" />
    </div>
    <div class="title05">
      <ServerFoot :data="serverData" :show="true"/>
      <ServerCl :show="false" :Cl="Cl"/>
    </div>
    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";

import Title from "../components/trademarkRegistration/title.vue";
import ServerTop from "../components/serverTop.vue";
import ServerFoot from "../components/serverFoot.vue";
import ServerCl from "../components/serverCl.vue";
export default {
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    TradHeader,
    Title,
    ServerTop,
    ServerFoot,
    ServerCl,
  },
  data() {
    return {
      Cl:["设计稿源文件（电子版）","定稿LOGO图原创版权证书"],
      serverData:[
        {
          title:'需求沟通',
          content:'在线咨询客服，会安排专业顾问及时电话联系您，充分沟通诊断确认服务需求。 '
        },
        {
          title:'签合同,付定金',
          content:'充分沟通确认需求后，双方签订服务合同，约定付款方式和交付时间，甲方支付定金后技术团队开始执行。'
        },
        {
          title:'阶段性成果交付',
          content:'技术团队严格按照品牌搜索占位服务流程交付阶段性成果，甲方支付尾款后完成剩下服务项目和内容。'
        },
        {
          title:'完成服务内容',
          content:'技术团队按双方合同约定完成全部服务内容。'
        },
        {
          title:'服务完成    ',
          content:''
        },
      ]
    };
  },
};
</script>

<style scoped lang="scss">
.index {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .back {
    width: 100%;
    height: 28.75rem /* 460px -> 28.75rem */;
    background-image: url("../imger/station/banner.jpg");
    background-size: 100% 100%;
    // background-repeat: no-repeat;
  }
  .header01 {
    width: 75rem /* 1200px -> 75rem */;
    height: 3.125rem /* 50px -> 3.125rem */;
    padding: 0.625rem /* 10px -> 0.625rem */;
    box-sizing: border-box;
    font-size: 1.25rem /* 20px -> 1.25rem */;
    color: #333333;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    .lan {
      width: 0.3125rem /* 5px -> 0.3125rem */;
      height: 1.6875rem /* 27px -> 1.6875rem */;
      margin-right: 0.875rem /* 14px -> 0.875rem */;
      background-color: #4d89f1;
    }
  }
  .title01 {
    width: 100%;
    height: 44.375rem /* 710px -> 44.375rem */;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .title {
      width: 100%;
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      .titletubiaoleft {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-right: 1.25rem /* 20px -> 1.25rem */;
      }
      .titletubiaoright {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-left: 1.25rem /* 20px -> 1.25rem */;
      }
    }
    .title01word {
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #666666;
      line-height: 2.6875rem /* 43px -> 2.6875rem */;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .pricture {
      width: 74.375rem /* 1190px -> 74.375rem */;
    }
    .bigbox {
      width: 75rem /* 1200px -> 75rem */;
      height: 25.1875rem /* 403px -> 25.1875rem */;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .box {
        width: 21.94375rem /* 351.1px -> 21.94375rem */;
        height: 11.375rem /* 182px -> 11.375rem */;
        background-color: #fff;
        box-shadow: 0 0 10px 3px rgba($color: #0c0408, $alpha: 0.11);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .boxhead {
          width: 100%;
          height: 2.5rem /* 40px -> 2.5rem */;
          display: flex;
          justify-content: center;
          .circle {
            width: 2.5rem /* 40px -> 2.5rem */;
            height: 2.5rem /* 40px -> 2.5rem */;
          }
        }
        .boxbody {
          width: 100%;
          height: 6.0625rem /* 97px -> 6.0625rem */;
          display: flex;
          justify-content: space-around;
          .boxword {
            width: 11.25rem /* 180px -> 11.25rem */;
            font-size: 1rem /* 16px -> 1rem */;
            color: #666666;
            line-height: 1.6875rem /* 27px -> 1.6875rem */;
          }
          .boxpic {
            width: 7.8125rem /* 125px -> 7.8125rem */;
            height: 6.0625rem /* 97px -> 6.0625rem */;
          }
        }
      }
    }
    .zhuliu {
      width: 66.375rem /* 1062px -> 66.375rem */;
    }
  }

  .title02 {
    background-color: #e5efff;
  }

  .title03 {
    width: 100%;
    height: 76.0625rem /* 1217px -> 76.0625rem */;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .title {
      width: 100%;
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      .titletubiaoleft {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-right: 1.25rem /* 20px -> 1.25rem */;
      }
      .titletubiaoright {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-left: 1.25rem /* 20px -> 1.25rem */;
      }
    }
    .title03word {
      width: 74.375rem /* 1190px -> 74.375rem */;
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #666666;
      line-height: 2.6875rem /* 43px -> 2.6875rem */;
    }
    .pricture {
      width: 48.875rem /* 782px -> 48.875rem */;
    }
  }
  .title05{
    width: 75rem /* 1200px -> 75rem */;

  }
}
</style>